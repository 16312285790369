import { reactive, computed, watch, ref } from 'vue'
import apiCall from '@/utils/api'
import { requestStatus } from '@/utils/constants'
import { useRouter, useSetToUpperCase } from '@/utils/vue_helpers'
import { routes } from '@/router/routes'

export function useGeneralBilling() {
    const steps = {
        ADD_BILL_ITEMS: 1,
        REVIEW: 2,
        VIEW_BILL: 3,
    }
    const currentStep = ref(steps.ADD_BILL_ITEMS)

    const inAddBillItems = computed(() => {
        return currentStep.value === steps.ADD_BILL_ITEMS
    })

    const inReview = computed(() => {
        return currentStep.value === steps.REVIEW
    })

    const transitionClass = ref('slide-left')

    const generalBillingData = reactive({
        billItems: [],
        selectedBillItem: null,
        total: 0,
        subcountyId: null,
        wardId: null,
        remarks: ''
    })

    useSetToUpperCase({
        textRef: computed(() => generalBillingData.remarks),
        setText: (newText) => { generalBillingData.remarks = newText }
    })

    const createBillRequestStatus = ref(requestStatus.NOT_SENDING)

    const inView = {
        viewBill: computed(() => {
            return currentStep.value === steps.VIEW_BILL
        })
    }

    const creatingBill = computed(() => {
        const billIsBeingCreated = createBillRequestStatus.value === requestStatus.SENDING
        return billIsBeingCreated
    })

    const selectedBillItemNotEmpty = computed(() => {
        const selectedBillItemNotEmpty = generalBillingData.selectedBillItem !== null
        if (selectedBillItemNotEmpty) {
            return true
        }
        return false
    })

    const billItemsNotEmpty = computed(() => {
        const billItems = generalBillingData.billItems
        const billItemsNotEmpty = billItems !== null && billItems.length > 0
        if (billItemsNotEmpty) {
            return true
        }
        return false
    })

    const createdBill = ref(null)

    const createBillNotEmpty = computed(() => {
        return createdBill !== null
    })

    function goToStep(step) {
        const goingToAddBillItems = step === steps.ADD_BILL_ITEMS
        const goingToReview = step === steps.REVIEW

        if (goingToAddBillItems) {
            transitionClass.value = 'slide-right'
        } else if (goingToReview) {
            transitionClass.value = 'slide-left'
        }

        currentStep.value = step
    }

    function setSelectedBillItem(bill) {
        const initialQuantity = 1
        const selectedBillItem = {
            bill_name: bill.fee_name,
            fee_id: bill.id,
            unit_cost: bill.amount,
            uom: bill.uom,
            quantity: initialQuantity,
            manual_amount_entry: bill.manual_amount_entry
        }
        selectedBillItem.sub_total = Number(bill.amount) * 1
        generalBillingData.selectedBillItem = selectedBillItem
    }

    function updateSelectedBillItemTotalCost(quantity) {
        if (selectedBillItemNotEmpty.value) {
            const selectedBillItem = generalBillingData.selectedBillItem
            const newTotal = selectedBillItem.unit_cost * quantity
            selectedBillItem.sub_total = newTotal
            selectedBillItem.quantity = quantity
        }
    }

    function addSelectedBillItem() {
        const billItem = { ...generalBillingData.selectedBillItem }
        const newTotal = generalBillingData.total + billItem.sub_total
        generalBillingData.total = newTotal
        generalBillingData.billItems.push(billItem)
        generalBillingData.selectedBillItem = null
    }

    function removeBillItem(index) {
        const billItems = generalBillingData.billItems
        const billItemTotalCost = billItems[index].sub_total
        const newTotal = generalBillingData.total - billItemTotalCost

        generalBillingData.billItems.splice(index, 1)
        generalBillingData.total = newTotal
    }

    const { router, route } = useRouter()

    function watchForShouldGoBackToGeneralBilling() {
        watch(route, (newVal, oldVal) => {
            const previousRouteWasViewBill = !!oldVal?.query?.bill_generated === true
            const currentRouteIsNotViewBill = !!newVal?.query?.bill_generated === false
            if (previousRouteWasViewBill && currentRouteIsNotViewBill) {
                goToStep(steps.ADD_BILL_ITEMS)
            }
        })
    }

    function addViewBillQueryToRoute() {
        router.value.push(routes.generalBilling.withQuery({ billGenerated: true }))
    }

    async function createBill({ subcountyId, wardId, townId }) {
        const requestData = {
            bill_items: generalBillingData.billItems,
            sub_county_id: subcountyId,
            ward_id: wardId,
            town_id: townId,
            remarks: generalBillingData.remarks
        }
        try {
            createBillRequestStatus.value = requestStatus.SENDING
            const response = await apiCall({ url: '/api/client/bill', data: requestData, method: 'POST' })
            createdBill.value = response.bill
            createBillRequestStatus.value = requestStatus.COMPLETE
            currentStep.value = steps.VIEW_BILL
            addViewBillQueryToRoute()
        } catch (error) {
            console.log(error)
            createBillRequestStatus.value = requestStatus.ERROR
        }
    }

    return {
        currentStep, steps, transitionClass,
        createdBill, createBillNotEmpty, createBill, billItemsNotEmpty, goToStep,
        generalBillingData, selectedBillItemNotEmpty, setSelectedBillItem,
        updateSelectedBillItemTotalCost, addSelectedBillItem, removeBillItem,
        inAddBillItems, inReview, creatingBill, watchForShouldGoBackToGeneralBilling,

    }
}