<script setup>
import Pagination from "@/components/utils/Pagination.vue";
import LoadingArea from "@/components/utils/LoadingArea.vue";
import { getFormattedMoney } from "@/utils/functions.js";
import LabelledField from "@/components/utils/LabelledField.vue";
import { actions } from "@/utils/constants";
import ActionButton from "@/components/utils/ActionButton.vue";
import Table from "@/components/utils/Table.vue";
import { computed, ref } from "vue";

const props = defineProps({
  subpartFees: {
    type: Array,
    default: null,
  },
  subpartFeesNotEmpty: {
    type: Boolean,
    default: false,
  },
  searchValue: {
    type: String,
    default: null,
  },
  loading: {
    type: Boolean,
    default: null,
  },
  paginationData: {
    type: Object,
    default: null,
  },
  selectedBillItemNotEmpty: {
    type: Boolean,
    default: false,
  },
  generalBillingData: {
    type: Object,
    default: false,
  },
});

const emit = defineEmits(["update:searchValue"]);

const searchModel = computed({
  get() {
    return props.searchValue;
  },
  set(newVal) {
    emit("update:searchValue", newVal);
  },
});

function isBillSelected(bill) {
  if (props.selectedBillItemNotEmpty) {
    const selectedBillId = props.generalBillingData.selectedBillItem.fee_id;
    const billSelected = selectedBillId === bill.id;
    if (billSelected) {
      return true;
    }
  }

  return false;
}

const searchForm = ref(null);
</script>

<template>
  <v-container fluid class="pa-3 pa-md-0">
    <v-form @submit.prevent="" ref="searchForm">
      <v-row justify="center">
        <v-col cols="12" md="6">
          <LabelledField label="Search">
            <v-text-field
              class="text-field-background mb-2"
              outlined
              dense
              v-model="searchModel"
              clearable
            />
          </LabelledField>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12" class="pa-0">
        <LoadingArea :loading="loading">
          <v-row no-gutters>
            <v-col cols="12" class="pa-0">
              <Table
                :headers="[
                  { text: 'Fee Name', value: 'fee_name' },
                  { text: 'Unit of Measure', value: 'uom' },
                  { text: 'Amount', value: 'amount' },
                  { text: 'Select', value: 'select', action: true },
                ]"
                :items="subpartFees"
                :loading="loading"
                emptyText="No Fees Found"
              >
                <template #header.uom="{header}">
                    <div :style="{ minWidth: $mobileBreakpoint ? '94px' : null }">{{ header.text }}</div>
                </template>

                <template #item.fee_name="{ item: subpartFee }">
                  <span
                    :class="{ 'success--text': isBillSelected(subpartFee) }"
                    >{{ subpartFee.fee_name }}</span
                  >
                </template>

                <template #item.uom="{ item: subpartFee }">
                  <span
                    :class="{ 'success--text': isBillSelected(subpartFee) }"
                    >{{ subpartFee.uom }}</span
                  >
                </template>

                <template #item.amount="{ item: subpartFee }">
                  <span
                    :class="{ 'success--text': isBillSelected(subpartFee) }"
                    >{{ getFormattedMoney(subpartFee.amount) }}</span
                  >
                </template>

                <template #item.select="{ item: subpartFee }">
                  <ActionButton
                    v-if="isBillSelected(subpartFee)"
                    :action="actions.canUnselect"
                    inTable
                  />

                  <ActionButton
                    v-else
                    :action="actions.canSelect"
                    inTable
                    @click="$emit('selectBillClicked', subpartFee)"
                  />
                </template>
              </Table>

              <v-simple-table v-if="false">
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">Fee Name</th>
                      <th class="text-left">Unit of Measure</th>
                      <th class="text-left">Amount</th>
                      <th class="text-right">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    <template v-if="subpartFeesNotEmpty">
                      <tr v-for="bill in subpartFees">
                        <td>
                          <div
                            :class="{ 'success--text': isBillSelected(bill) }"
                          >
                            {{ bill.fee_name }}
                          </div>
                        </td>
                        <td>
                          <div
                            :class="{ 'success--text': isBillSelected(bill) }"
                          >
                            {{ bill.uom }}
                          </div>
                        </td>
                        <td>
                          <div
                            :class="{ 'success--text': isBillSelected(bill) }"
                          >
                            {{ getFormattedMoney(bill.amount) }}
                          </div>
                        </td>
                        <td class="text-right">
                          <v-btn
                            v-if="!isBillSelected(bill)"
                            :class="{
                              'text-none primary': true,
                            }"
                            small
                            @click="$emit('selectBillClicked', bill)"
                            >Select
                            <v-icon right> mdi-checkbox-blank-outline </v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            :class="{
                              'text-none success': true,
                            }"
                            small
                            >Selected
                            <v-icon right> mdi-checkbox-marked-outline </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <tr v-else-if="loading">
                      <td colspan="6">
                        <div class="text-center">Loading</div>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="6">
                        <div class="text-center">
                          No items matched your search
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row justify-md="end">
            <v-col cols="auto">
              <Pagination
                :totalNumberOfItems="paginationData.total"
                :itemsPerPage="paginationData.perPage"
                :currentPage="paginationData.currentPage"
                @paginationClicked="$emit('paginationClicked', $event)"
              />
            </v-col>
          </v-row>
        </LoadingArea>
      </v-col>
    </v-row>
  </v-container>
</template>
