<script setup>
import BillItems from '../BillItems.vue';

const props = defineProps({
    generalBillingData: {
        type: Object,
        default: false
    },
    billItemsNotEmpty: {
        type: Boolean,
        default: false
    },
    steps: {
        type: Object,
        default: null
    },
    inAddBillItems: {
        type: Boolean,
        default: false
    },
    inReview: {
        type: Boolean,
        default: false
    },
    creatingBill: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['billCreated'])
</script>

<template>
    <v-container fluid class="pa-0">
        <v-row v-if="$mobileBreakpoint" justify="center">
            <v-col cols="auto" class="font-weight-bold">
                Bill Items
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pa-0">
                <BillItems :billItems="generalBillingData.billItems" :total="generalBillingData.total" billItemsRemovable
                    @removeBillItem="$emit('removeBillItem', $event)" />
            </v-col>
        </v-row>
        <v-row v-if="inAddBillItems" justify="center" justify-md="end" align="center" class="mt-16">
            <v-col cols="auto" class="pa-0">
                <v-btn depressed color="primary" class="text-none" @click="$emit('nextClicked')">
                    Next
                    <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>