var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Table,{attrs:{"headers":[
    {
      text: 'Item Name',
      value: 'itemName',
      fn: (billItem) => _setup.getItemName(billItem),
    },
    {
      text: 'Quantity',
      value: 'quantity',
    },
    {
      text: 'Unit of Measure',
      value: 'uom',
    },
    {
      text: 'Subtotal',
      value: 'sub_total',
      fn: (billItem) => _setup.getFormattedMoney(billItem.sub_total),
    },
    ...(_setup.props.billItemsRemovable
      ? [
          {
            text: '',
            value: 'billItemRemovable',
            action: true,
          },
        ]
      : []),
  ],"items":_vm.billItems},scopedSlots:_vm._u([{key:"header.billItemRemovable",fn:function(){return [_c('span',[_vm._v("Remove")])]},proxy:true},{key:"item.billItemRemovable",fn:function({ item: billItem }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.removeItem,"inTable":""},on:{"click":function($event){_vm.$emit('removeBillItem', _vm.billItems.indexOf(billItem))}}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }