import { reactive, onMounted, computed, watch } from 'vue'
import { requestStatus } from '@/utils/constants.js'
import { stringNotEmpty, debounce } from '@/utils/functions.js'
import { usePaginatedDataWithDebouncedSearch } from '@/utils/vue_helpers'
import apiCall from '@/utils/api.js'

export function useSubpartFees(){
    const {
        paginationData: subpartFees,
        searchModel,
        debouncedSearchModel,
        debouncedSearchModelNotEmpty
    } = usePaginatedDataWithDebouncedSearch()

    const url = computed(() => {
        let url = `/api/client/sub-part-fees?page=${subpartFees.paginationData.currentPage}`
        if (debouncedSearchModelNotEmpty.value) {
            url = url + `&type=search&search=${debouncedSearchModel.value}`
        }
        return url
    })

    watch(url, (newVal) => {
        subpartFees.fetchData(newVal)
    }, {
        immediate: true
    })

    return {
        subpartFees,
        searchModel
    }
}

export const subpartFeesData = reactive({
    fetchedFees: [],
    paginationData: {
        currentPage: 1,
        perPage: 0,
        total: 0,
        visible: 10
    },
    requestStatus: requestStatus.NOT_SENDING,
    searchModel: ''
})

export const subpartFeesLoading = computed(() => {
    const requestLoading = subpartFeesData.requestStatus === requestStatus.SENDING
    if (requestLoading) {
        return true
    }
    return false
})

export function clearSubpartFeesDataOnMount() {
    onMounted(async () => {
        subpartFeesData.fetchedFees = []
        subpartFeesData.searchModel = ''
        subpartFeesData.requestStatus = requestStatus.NOT_SENDING
        await fetchSubpartFees()
    })
}

export function setPaginationData(serverResponse) {
    subpartFeesData.paginationData.currentPage = serverResponse.current_page
    subpartFeesData.paginationData.total = serverResponse.total
    subpartFeesData.paginationData.perPage = serverResponse.per_page
}

export function changePageNumber(pageNumber) {
    subpartFeesData.paginationData.currentPage = pageNumber
}

function setToFirstPage() {
    subpartFeesData.paginationData.currentPage = 1
}
const debouncedFetchSubpartFees = debounce(() => {
    setToFirstPage()
    fetchSubpartFees()
}, 1000)
const getSearchModel = computed(()=>{
    return subpartFeesData.searchModel
})
watch(getSearchModel,()=>{
    debouncedFetchSubpartFees()
})

export async function fetchSubpartFees() {
    const searchFieldNotEmpty = stringNotEmpty(subpartFeesData.searchModel)
    const currentPage = subpartFeesData.paginationData.currentPage
    const urlForEmptySearch = `/api/client/sub-part-fees?page=${currentPage}`
    let searchUrl = urlForEmptySearch
    if (searchFieldNotEmpty) {
        searchUrl = `/api/client/sub-part-fees?type=search&search=${subpartFeesData.searchModel}&page=${currentPage}`
    }
    try {
        subpartFeesData.requestStatus = requestStatus.SENDING
        const response = await apiCall({
            url: searchUrl,
            method: 'GET'
        })
        subpartFeesData.fetchedFees = response.data
        setPaginationData(response)
        subpartFeesData.requestStatus = requestStatus.COMPLETE
    } catch (error) {
        subpartFeesData.requestStatus = requestStatus.ERROR
        console.log(error)
    }
}