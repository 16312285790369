<script setup>
import { computed, ref } from "vue";
import { fieldRequired, isNumber, minValue } from "@/utils/input_validation";
import {
  snackbarData,
  resetSnackbarOnMount,
} from "@/components/utils/snackbar/logic";
import LabelledField from "@/components/utils/LabelledField.vue";

resetSnackbarOnMount();

const props = defineProps({
  selectedBillItemNotEmpty: {
    type: Boolean,
    default: false,
  },
  generalBillingData: {
    type: Object,
    default: false,
  },
});

const emit = defineEmits([
  "updateSelectedBillItemTotalCost",
  "addSelectedBillItem",
]);

const inputModels = computed(() => {
  let models = {
    billItemName: "",
    unitCost: "",
    subtotalCost: "",
  };
  if (props.selectedBillItemNotEmpty) {
    const selectedBillItem = props.generalBillingData.selectedBillItem;
    models = {
      billItemName: selectedBillItem.bill_name,
      unitCost: selectedBillItem.unit_cost,
      subtotalCost: selectedBillItem.sub_total,
    };
  }
  return models;
});

const quantityModel = computed({
  get() {
    if (props.selectedBillItemNotEmpty) {
      return props.generalBillingData.selectedBillItem.quantity;
    }
    return "";
  },
  set(newVal) {
    emit("updateSelectedBillItemTotalCost", newVal);
  },
});

const form = ref(null);
function onAddButtonClicked() {
  if (!props.selectedBillItemNotEmpty) {
    snackbarData.text = "Please select an item";
    snackbarData.color = "red";
    snackbarData.open = true;
    return;
  }
  const formValid = form.value.validate();
  if (formValid) {
    emit("addSelectedBillItem");
    snackbarData.text = "Bill item added";
    snackbarData.color = "success";
    snackbarData.open = true;
  }
}

const noBillItemSelected = computed(() => {
  const noBillItemSelected = !props.selectedBillItemNotEmpty;
  if (noBillItemSelected) {
    return true;
  }
  return false;
});

const quantityFieldRules = computed(() => {
  if (props.selectedBillItemNotEmpty) {
    return [];
  }
  return [fieldRequired, isNumber];
});
</script>

<template>
  <v-container fluid class="px-md-0">
    <v-row justify="center">
      <v-col cols="auto">
        <div class="font-weight-bold title">Bill Item Details</div>
      </v-col>
    </v-row>
    <v-form ref="form" @submit.prevent.prevent="onAddButtonClicked">
      <v-row>
        <v-col cols="12" md="3" class="px-0 px-md-3">
          <LabelledField label="Bill Item Name">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  disabled
                  readonly
                  hide-details="auto"
                  class="text-field-background"
                  :value="inputModels.billItemName"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ inputModels.billItemName }}</span>
            </v-tooltip>
          </LabelledField>
        </v-col>

        <v-col cols="12" md="3" class="px-0 px-md-3">
          <LabelledField label="Unit Cost">
            <v-text-field
              outlined
              dense
              disabled
              readonly
              hide-details="auto"
              :value="inputModels.unitCost"
              class="text-field-background"
            />
          </LabelledField>
        </v-col>

        <v-col cols="12" md="3" class="px-0 px-md-3">
          <LabelledField label="Quantity" required>
            <v-text-field
              outlined
              dense
              :hide-details="noBillItemSelected ? true : 'auto'"
              :disabled="noBillItemSelected"
              :rules="[fieldRequired, isNumber, minValue(1)]"
              v-model="quantityModel"
              class="text-field-background"
            />
          </LabelledField>
        </v-col>

        <v-col cols="12" md="3" class="px-0 px-md-3">
          <LabelledField label="Subtotal Cost">
            <v-text-field
              outlined
              dense
              disabled
              readonly
              hide-details="auto"
              :value="inputModels.subtotalCost"
              class="text-field-background"
            />
          </LabelledField>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto">
          <v-btn
            depressed
            class="success text-none"
            @click="onAddButtonClicked"
          >
            Add
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
