<script setup>
import AddBillItemsSearch from "./AddBillItemsSearch.vue";
import AddBillItem from "./AddBillItem.vue";
import BillItems from "./BillItems.vue";
import Location from "./Location.vue";
import ViewBill from "../view_bills/ViewBill.vue";
import LoadingButton from "@/components/utils/LoadingButton.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { useGeneralBilling } from "./general_billing_business_logic";
import { useSubpartFees } from "./subpart_fees_business_logic";
import { scrollToElement } from "@/utils/functions";
import { useSubcountyWard, countyIsKirinyaga } from "@/utils/vue_helpers";
import {
  snackbarData,
  resetSnackbarOnMount,
} from "@/components/utils/snackbar/logic";
import { fieldRequired } from "@/utils/input_validation";
import { computed, ref, watch } from "vue";

resetSnackbarOnMount();

const {
  currentStep,
  steps,
  transitionClass,
  createdBill,
  createBillNotEmpty,
  createBill,
  billItemsNotEmpty,
  goToStep,
  generalBillingData,
  selectedBillItemNotEmpty,
  setSelectedBillItem,
  updateSelectedBillItemTotalCost,
  addSelectedBillItem,
  removeBillItem,
  inAddBillItems,
  inReview,
  creatingBill,
  watchForShouldGoBackToGeneralBilling,
} = useGeneralBilling();

watchForShouldGoBackToGeneralBilling();

watch([currentStep, createBillNotEmpty], () => {
  const inViewBill = currentStep.value === steps.VIEW_BILL;
  if (inViewBill) {
    showBillCreatedSnackbar();
  }
});

const { subcounties, wards, towns, locationData, locationDataLoading } =
  useSubcountyWard();

const { searchModel, subpartFees } = useSubpartFees();

const subcountyWardForm = ref(null);

const billRemarksForm = ref(null);

const billRemarksFieldRules = computed(() => {
  if (countyIsKirinyaga) {
    return [fieldRequired];
  }
  return [];
});

function showBillCreatedSnackbar() {
  snackbarData.text = "Bill Created";
  snackbarData.color = "success";
  snackbarData.open = true;
}

function onNextClicked() {
  const subcountyWardFormValid = subcountyWardForm.value?.validate();
  if (!subcountyWardFormValid) {
    scrollToElement(subcountyWardForm.value?.$el);
    return;
  }

  const billItemsEmpty = !billItemsNotEmpty.value;
  if (billItemsEmpty) {
    snackbarData.text = "Please add a bill item";
    snackbarData.color = "red";
    snackbarData.open = true;
    return;
  }
  goToStep(steps.REVIEW);
}

async function onGenerateBillClicked() {
  const shouldValidateForm = countyIsKirinyaga.value;
  const billRemarksFormValid =
    !shouldValidateForm || billRemarksForm.value?.validate();
  if (billItemsNotEmpty.value && billRemarksFormValid) {
    const bill = await createBill({
      subcountyId: locationData.subcountyId,
      wardId: locationData.wardId,
      townId: locationData.townId,
    });
  }
}

function onPaginationClicked(newPage) {
  subpartFees.setNewPage(newPage);
}

function onSelectBillClicked(bill) {
  setSelectedBillItem(bill);
}

function onUpdateSelectedBillItemTotalCost(quantity) {
  updateSelectedBillItemTotalCost(quantity);
}

function onAddSelectedBillItem() {
  addSelectedBillItem();
}

function onBillItemsPreviousClicked() {
  goToStep(steps.ADD_BILL_ITEMS);
}

function onRemoveBillItemClicked(index) {
  if (inAddBillItems.value) {
    removeBillItem(index);
  } else if (inReview.value) {
    removeBillItem(index);
    goToStep(steps.ADD_BILL_ITEMS);
  }
}
</script>

<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto" class="text-h5 primary--text font-weight-bold">
        General Billing
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pa-md-4">
        <!-- <v-card class="pa-10"> -->
        <transition :name="transitionClass" mode="out-in">
          <v-container
            v-if="currentStep === steps.ADD_BILL_ITEMS"
            :key="steps.ADD_BILL_ITEMS"
            fluid
            class="pa-0"
          >
            <v-row>
              <v-col cols="12">
                <div class="text-h6 text-center font-weight-bold primary--text">
                  Select Bill Items
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-form ref="subcountyWardForm">
                  <Location
                    :subcountyId.sync="locationData.subcountyId"
                    :wardId.sync="locationData.wardId"
                    :townId.sync="locationData.townId"
                    :subcounties="subcounties"
                    :wards="wards"
                    :towns="towns"
                    :locationDataLoading="locationDataLoading"
                  />
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-divider />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <AddBillItemsSearch
                  :subpartFees="subpartFees.fetchedData"
                  :loading="subpartFees.fetchingData"
                  :searchValue.sync="searchModel"
                  :subpartFeesNotEmpty="subpartFees.fetchedDataNotEmpty"
                  :paginationData="subpartFees.paginationData"
                  :selectedBillItemNotEmpty="selectedBillItemNotEmpty"
                  :generalBillingData="generalBillingData"
                  @paginationClicked="onPaginationClicked"
                  @selectBillClicked="onSelectBillClicked"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-8">
                <v-divider />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-0 px-md-0">
                <AddBillItem
                  :selectedBillItemNotEmpty="selectedBillItemNotEmpty"
                  :generalBillingData="generalBillingData"
                  @updateSelectedBillItemTotalCost="
                    onUpdateSelectedBillItemTotalCost
                  "
                  @addSelectedBillItem="onAddSelectedBillItem"
                  @removeBillItemClicked="onRemoveBillItemClicked"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-md-8">
                <v-divider />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <BillItems
                  :generalBillingData="generalBillingData"
                  :billItemsNotEmpty="billItemsNotEmpty"
                  :steps="steps"
                  :inAddBillItems="inAddBillItems"
                  :inReview="inReview"
                  :creatingBill="creatingBill"
                  @nextClicked="onNextClicked"
                  @removeBillItem="onRemoveBillItemClicked"
                />
              </v-col>
            </v-row>
          </v-container>

          <v-container
            v-else-if="currentStep === steps.REVIEW"
            :key="steps.REVIEW"
            fluid
            class="pa-0"
          >
            <v-row>
              <v-col cols="12">
                <div
                  class="text-md-h6 text-center font-weight-bold primary--text mb-8"
                >
                  Review Bill
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <BillItems
                  @generateBillClicked="onGenerateBillClicked"
                  :generalBillingData="generalBillingData"
                  :billItemsNotEmpty="billItemsNotEmpty"
                  :steps="steps"
                  :inAddBillItems="inAddBillItems"
                  :inReview="inReview"
                  :creatingBill="creatingBill"
                  @nextClicked="onNextClicked"
                  @removeBillItem="onRemoveBillItemClicked"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-form ref="billRemarksForm">
                  <LabelledField label="Remarks" :required="countyIsKirinyaga">
                    <v-textarea
                      outlined
                      dense
                      class="text-field-background"
                      hide-details="auto"
                      v-model="generalBillingData.remarks"
                      :rules="billRemarksFieldRules"
                    />
                  </LabelledField>
                </v-form>
              </v-col>
            </v-row>

            <v-row justify="center" justify-md="space-between">
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="secondary"
                  class="text-none"
                  @click="onBillItemsPreviousClicked"
                >
                  <v-icon left> mdi-chevron-left</v-icon>
                  Previous
                </v-btn>
              </v-col>
              <v-col :cols="creatingBill ? '3' : 'auto'">
                <LoadingButton
                  block
                  :class="{
                    'primary white--text': !creatingBill,
                  }"
                  :loading="creatingBill"
                  :disabled="creatingBill"
                  @click="onGenerateBillClicked"
                >
                  <div class="d-flex align-center white--text">
                    <span>Generate Bill</span>
                    <v-icon
                      :style="{
                        color: 'white !important',
                      }"
                      right
                      >mdi-receipt-text</v-icon
                    >
                  </div>
                  <template #loadingText> Generating Bill </template>
                </LoadingButton>
              </v-col>
            </v-row>
          </v-container>

          <v-container
            v-else-if="currentStep === steps.VIEW_BILL && createBillNotEmpty"
            :key="steps.VIEW_BILL"
            fluid
            class="pa-0"
          >
            <v-row>
              <v-col cols="12">
                <ViewBill :bill="createdBill" showGoBack>
                  <template #title>
                    <div class="title primary--text text-center">
                      Bill Details
                    </div>
                  </template>

                  <template #goBackButton>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn class="pl-md-0" plain @click="goToStep(steps.ADD_BILL_ITEMS)" v-on="on" v-bind="attrs">
                          <v-icon left class="primary--text">mdi-arrow-left</v-icon>
                          <span class="text-caption text-none primary--text"
                            >Back to General billing</span
                          >
                        </v-btn>
                      </template>
                      <span> Back To General Billing </span>
                    </v-tooltip>
                  </template>
                </ViewBill>
              </v-col>
            </v-row>
          </v-container>
        </transition>
        <!-- </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>
