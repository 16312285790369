<script setup>
import { computed, ref } from "vue";
import {
  getFormattedBillDate,
  getBillAmount,
  getFormattedMoney,
} from "@/utils/functions";
import BillItems from "../BillItems.vue";
import ViewPdf from "@/components/utils/ViewPdf.vue";
import { useRouter, useDownloadFile } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";
import bill from "@/store/modules/bill";
import BackButton from "@/components/utils/BackButton.vue";

const { router } = useRouter();

const props = defineProps({
  bill: {
    type: Object,
    default: null,
  },
  showGoBack: {
    type: Boolean,
    default: false,
  },
});

const tabs = {
  BILL_ITEMS: 0,
  INVOICES: 1,
};
const currentTab = ref(tabs.BILL_ITEMS);

const printBillOpen = ref(false);

const billNotEmpty = computed(() => {
  return props.bill !== null;
});

const totalPaid = computed(() => {
  let total = 0;
  const billPaid = props.bill.work_flow_status.status_name === "Paid";
  if (billNotEmpty.value && billPaid) {
    for (const invoice of props.bill.bills_invoices) {
      total += Number(invoice.invoice.invoice_amount);
    }
  }
  return getFormattedMoney(total);
});

const totalBillAmount = computed(() => {
  if (billNotEmpty.value) {
    return getFormattedMoney(getBillAmount(props.bill));
  }
  return 0;
});

const numberOfBillItems = computed(() => {
  if (billNotEmpty.value) {
    return props.bill.bill_items.length;
  }
  return 0;
});

const numberOfInvoices = computed(() => {
  if (billNotEmpty.value) {
    return props.bill.bills_invoices.length;
  }
  return 0;
});

const billNotPaid = computed(() => {
  return props.bill.work_flow_status.status_name === "Unpaid";
});

function onPayBillClicked() {
  const paymentRoute = routes.payment.withQuery({
    billNumbersToPayAsString: props.bill.bill_number,
    directPayment: true,
    billsToPay: [props.bill],
  });
  router.value.push(paymentRoute);
}

const { downloadFile } = useDownloadFile();

function onPrintBillClicked() {
  downloadFile({ billId: props.bill.id, billNumber: props.bill.bill_number });
}
</script>

<template>
  <v-container v-if="billNotEmpty" fluid class="pa-1">
    <v-row align="center">
      <v-col cols="12" md="1">
        <slot name="goBackButton" v-if="showGoBack">
          <BackButton @click="$emit('goBackClicked')">
            Back to Bills
          </BackButton>
        </slot>
      </v-col>
      <v-col cols="12" md="10">
        <slot name="title">
          <div class="font-weight-bold primary--text text-center text-h6">
            Bill Details
          </div>
        </slot>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="">
      <v-col cols="12" md="6">
        <v-container fluid class="pa-1">
          <v-row dense>
            <v-col cols="5" md="4" class="font-weight-bold"> Bill Number: </v-col>
            <v-col cols="auto">
              {{ props.bill.bill_number }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="5" md="4" class="font-weight-bold"> Bill Type: </v-col>
            <v-col cols="auto">
              {{ props.bill.bill_type.name }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="5" md="4" class="font-weight-bold"> Status: </v-col>
            <v-col cols="auto">
              {{ props.bill.work_flow_status.status_name }}
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" md="6">
        <v-container fluid class="pa-1">
          <v-row dense>
            <v-col cols="5" md="4" class="font-weight-bold"> Total Amount: </v-col>
            <v-col cols="7" md="8">
              {{ totalBillAmount }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="5" md="4" class="font-weight-bold"> Total Paid: </v-col>
            <v-col cols="7">
              <span class="font-weight-bold green--text">{{ totalPaid }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="5" md="4" class="font-weight-bold"> Date: </v-col>
            <v-col cols="7">
              {{ getFormattedBillDate(props.bill.created_at) }}
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="auto">
        <v-btn class="text-none" color="success" @click="onPrintBillClicked">
          <span>Download Bill</span><v-icon right>mdi-download-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="billNotPaid" justify="end">
      <v-col cols="auto">
        <v-btn class="text-none" color="secondary" @click="onPayBillClicked">
          <span>Pay Bill</span><v-icon right>mdi-cash</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="my-6 mx-1">
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs v-model="currentTab">
          <v-tabs-slider color="primary" />

          <v-tab class="text-none">
            Bill Items ({{ numberOfBillItems }})
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row v-if="billNotEmpty">
      <v-col cols="12">
        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <BillItems
              :billItems="props.bill.bill_items"
              :total="totalBillAmount"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <ViewPdf
      v-if="printBillOpen"
      :billId="bill.id"
      :open.sync="printBillOpen"
    />
  </v-container>
</template>
