<script setup>
import LabelledField from '@/components/utils/LabelledField.vue'
import { fieldRequired } from '@/utils/input_validation'
import { countyIsKirinyaga } from '@/utils/vue_helpers';
import { computed } from 'vue';

const props = defineProps({
    subcountyId: {
        type: [Number],
        default: null
    },
    wardId: {
        type: [Number],
        default: null
    },
    townId: {
        type: [Number],
        default: null
    },
    subcounties: {
        type: Array,
        default: null
    },
    wards: {
        type: Array,
        default: null
    },
    towns: {
        type: Array,
        default: null
    },
    locationDataLoading: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['update:subcountyId', 'update:wardId', 'update:townId'])

const innerSubcountyId = computed({
    get() {
        return props.subcountyId
    }, set(newVal) {
        emit('update:subcountyId', newVal)
    }
})

const innerWardId = computed({
    get() {
        return props.wardId
    }, set(newVal) {
        emit('update:wardId', newVal)
    }
})

const innerTownId = computed({
    get() {
        return props.townId
    }, set(newVal) {
        emit('update:townId', newVal)
    }
})

const fields = computed(() => {
    const fields = [
        {
            label: 'Subcounty',
            items: props.subcounties,
            model: innerSubcountyId,
            itemValue: 'id',
            itemText: 'sub_county_name',
            rules: [fieldRequired]
        },
        {
            label: 'Ward',
            items: props.wards,
            model: innerWardId,
            itemValue: 'id',
            itemText: 'ward_name',
            rules: [fieldRequired]
        },
    ]

    if (countyIsKirinyaga.value) {
        fields.push({
            label: 'Town',
            items: props.towns,
            model: innerTownId,
            itemValue: 'id',
            itemText: 'town_name',
            rules: [fieldRequired]
        },)
    }

    return fields
})
const subcountyShouldHaveValue = (v) => {
    return !!innerSubcountyId.value || 'Please select a subcounty'
}
</script>

<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col v-for="field in fields" cols="12" :md="countyIsKirinyaga ? '4' : '6'">
                <LabelledField :label='field.label' required>
                    <v-select :items="field.items" v-model="field.model.value" class="text-field-background"
                        :item-value="field.itemValue" :item-text="field.itemText" outlined dense :rules="field.rules"
                        :loading="locationDataLoading" :disabled="locationDataLoading" hide-details="auto"></v-select>
                </LabelledField>
            </v-col>
        </v-row>
    </v-container>
</template>